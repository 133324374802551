type Task = () => Promise<void>

export default class SerialTaskRunner {
  private tasks: Task[] = []

  private isRunning = false

  addTask = (task: Task) => {
    this.tasks.push(task)
  }

  runTasks = async () => {
    while (this.tasks.length > 0) {
      await this.tasks.shift()?.()
    }
  }
}
