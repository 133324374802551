import whitelist from './whitelist.json'
import pick from 'lodash/pick'

const { events: eventsWhitelist, userProperties: intercomUserProperties } =
  whitelist.intercom
const intercomEvents = new Set(eventsWhitelist)

class IntercomService {
  didInit = false

  init() {
    if (this.didInit) return

    this.initIntercom()
  }

  initIntercom() {
    try {
      ;(function () {
        let i = function () {
          i.c(arguments)
        }
        let w = window
        let ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          let d = document
          i.q = []
          i.c = function (args) {
            i.q.push(args)
          }
          w.Intercom = i
          let l = function () {
            let s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = `https://widget.intercom.io/widget/${window.KEYS.intercomAppId}`
            document.head.appendChild(s)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
      window.Intercom('boot', {
        app_id: window.KEYS.intercomAppId,
        hide_default_launcher: true, // Don't show the intercom button
      })
      this.didInit = true
    } catch (error) {
      console.error('Error initiating Intercom: ', error)
    }
  }

  invokeIntercom(...args) {
    if (!this.didInit) {
      this.initIntercom()
    }

    window.Intercom(...args)
  }

  track(eventName, eventProperties) {
    if (intercomEvents.has(eventName)) {
      this.invokeIntercom('trackEvent', eventName, eventProperties)
    }
  }

  setUserProperties(userProperties) {
    const props = pick(userProperties, intercomUserProperties)

    if (Object.keys(props).length > 0) {
      this.invokeIntercom('update', props)
    }
  }

  setOnShowEvent(cb) {
    this.invokeIntercom('onShow', cb)
  }

  identifyUser(userId) {
    this.setUserProperties({ user_id: userId })
  }

  setName(name) {
    this.setUserProperties({ name })
  }

  setEmail(email) {
    this.setUserProperties({ email })
  }

  show() {
    this.showSpace('home')
  }

  showSpace(space) {
    this.invokeIntercom('showSpace', space)
  }
}

export const intercomService = new IntercomService()
