export enum LANGUAGES {
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  SPANISH = 'es',
}

export const SUPPORTED_LANGUAGES: LANGUAGES[] = [
  LANGUAGES.ENGLISH,
  LANGUAGES.SPANISH,
  LANGUAGES.FRENCH,
  LANGUAGES.GERMAN,
]

export const LANGUAGE_LOCALES = {
  en: 'English',
  fr: 'French',
  de: 'German',
  es: 'Spanish',
}
