import ReactPixel from 'react-facebook-pixel'
import { PRODUCT_TYPES, productTypeState } from '../../ProductTypeState'

function getPixelIdByProductType() {
  const productType = productTypeState.getProductType()
  switch (productType) {
    case PRODUCT_TYPES.CLASSIC:
      return window.KEYS.facebookPixelId
    case PRODUCT_TYPES.ART:
      return window.KEYS.facebookArtPixelId
    case PRODUCT_TYPES.GIFT_CARD:
      return window.KEYS.facebookPixelId
    case PRODUCT_TYPES.BUSINESS:
      return window.KEYS.facebookPixelId
    case PRODUCT_TYPES.PHOTO_BOOK:
      return window.KEYS.facebookPixelId
    default:
      console.error(
        `unknown product type when trying to get pixel id: ${productType}`
      )
  }
}

const FACEBOOK_PIXEL_OPTIONS = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
}

export function initFacebook() {
  ReactPixel.init(getPixelIdByProductType(), {}, FACEBOOK_PIXEL_OPTIONS)
}

export function setFacebookExternalId(userId) {
  ReactPixel.init(
    getPixelIdByProductType(),
    { external_id: userId },
    FACEBOOK_PIXEL_OPTIONS
  )
}

export function setFacebookAdvancedMatching(email) {
  ReactPixel.init(
    getPixelIdByProductType(),
    { em: email },
    FACEBOOK_PIXEL_OPTIONS
  )
}

export { ReactPixel as Pixel }
