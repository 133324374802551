import * as Amplitude from '@amplitude/analytics-browser'
import { getUrlParam } from '../../../utils/UrlUtils'
import {
  type BrowserOptions,
  type ValidPropertyType,
} from '@amplitude/analytics-types'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

export const amplitude = Amplitude
export async function initAmplitude(userId: string) {
  const options: BrowserOptions = {
    appVersion: window.KEYS.buildCommit || undefined,
    deviceId: getUrlParam('deviceId') || undefined,
    defaultTracking: {
      attribution: false,
    },
  }

  await amplitude.init(window.KEYS.amplitudeApiKey, userId, options).promise
}

export function startSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  })
  amplitude.add(sessionReplayTracking)
}

export async function updateAmplitudeUserProperties({
  set = {},
  unset = [],
}: {
  set?: Record<string, ValidPropertyType>
  unset?: string[]
}) {
  const identify = new Amplitude.Identify()
  for (const [key, value] of Object.entries(set)) {
    identify.set(key, value)
  }
  unset.forEach((key) => {
    identify.unset(key)
  })
  await amplitude.identify(identify).promise
}
