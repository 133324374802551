export const WHO_IS_IT_FOR_KEY = 'firstPurchaseTarget'
export const ATTRIBUTION_SURVEY_KEY = 'Attribution Survey'
export const PASSED_EMAIL_CAPTURE_SCREEN_KEY = 'Passed Email Capture Screen'

export const QUIZ_STEPS = {
  INTRO: 'intro',
  WHO_IS_IT_FOR: 'who-for',
  CHOOSE_GIFT: 'choose-gift',
  NAME: 'name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone',
  ATTRIBUTION_SURVEY: 'attribution',
  DIRECT_SMS_FLOW: 'sms',
}

export const WHO_IS_IT_FOR_OPTIONS = {
  ME: 'me',
  GIFT: 'gift',
}
