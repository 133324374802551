// TODO: Consider removing it, we shouldn't save large strings anyway to local storage and this bloats the vendors bundle
import * as pako from 'pako'

import { getUrlParam } from '../utils/UrlUtils'
import { logger } from './logger'

/* global localStorage */

const STRING_LENGTH_COMPRESSION_THRESHOLD = 50000 // Above this size, compress the string before saving to localStorage
const COMPRESSED_STRING_MAGIC = '#compressed#'

class StorageManager {
  static set(key, value) {
    let valueString = null
    if (value != null) {
      // not null or undefined
      valueString = JSON.stringify(value)

      // Compress the string if needed (don't compress experiments or tiles)
      if (
        valueString.length > STRING_LENGTH_COMPRESSION_THRESHOLD &&
        !['tiles', 'experiments'].includes(key)
      ) {
        try {
          valueString =
            COMPRESSED_STRING_MAGIC +
            pako.deflate(valueString, { to: 'string' })
        } catch (error) {
          logger.error(
            'Error while compressing value, saving without compression',
            error,
            { key }
          )
        }
      }
    }

    try {
      localStorage.setItem(key, valueString)
    } catch (e) {
      logger.warning('Write to local storage failed', {
        error: e,
        key,
      })
    }
  }

  static get(key) {
    let value = localStorage.getItem(key)

    // Decompress the string if needed
    if (value && value.startsWith(COMPRESSED_STRING_MAGIC)) {
      value = pako.inflate(value.slice(COMPRESSED_STRING_MAGIC.length), {
        to: 'string',
      })
    }

    return value && JSON.parse(value)
  }

  static getWithDefaultValue(key, defaultValue) {
    let value = this.get(key)
    if (value == null) {
      this.set(key, defaultValue)
      value = defaultValue
    }
    return value
  }

  static remove(key) {
    localStorage.removeItem(key)
    return true
  }

  static setIfEmpty(key, defaultValue = {}) {
    if (this.get(key) == null) {
      this.set(key, defaultValue)
    }
  }

  static setFromUrlParam(param, key, getValueFunction) {
    const paramValue = getUrlParam(param)
    if (paramValue !== undefined) {
      StorageManager.set(
        key,
        getValueFunction ? getValueFunction(paramValue) : paramValue
      )
    }
  }

  static getKeysByRegex(regex) {
    return Object.keys(localStorage).filter((key) => regex.test(key))
  }
}

export default StorageManager
