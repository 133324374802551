import { sha256 } from 'js-sha256'
import { getUserPhoneNumber } from '../User/phoneNumber'
import tiktokEvents from './platforms/tiktok-events.json'
import { getNowUnixTimestamp } from '../../utils/dateUtils'
import storageManager from '../StorageManager'
import { ADDRESSES_KEY } from '../../utils/AddressUtils'
import { mixtilesAxios } from '../../utils/ApiUtils'
import { productTypeState } from '../ProductTypeState'
import { getUserEmail } from '../userEmail'
import { logger } from '../logger'
import { getIPAddress } from '../countryService'

const ANALYTICS_URL = 'v4/analytics'

export const ServerSideAnalyticsPlatform = {
  Facebook: 'facebook',
  Klaviyo: 'klaviyo',
  Tiktok: 'tiktok',
}

function parseCookie() {
  let cookies = {}
  document.cookie.split(/; */).forEach((cookie) => {
    const [key, ...value] = cookie.split('=')
    cookies[key] = decodeURIComponent(value.join('='))
  })
  return cookies
}

class FacebookUtils {
  getUserAgent() {
    return navigator.userAgent
  }

  getAddressManagerDetails(userAddress = null) {
    const addressManagerDetails = {}
    if (userAddress) {
      Object.assign(
        addressManagerDetails,
        userAddress.phoneNumber ? { ph: sha256(userAddress.phoneNumber) } : null
      )
      Object.assign(
        addressManagerDetails,
        userAddress.city ? { ct: sha256(userAddress.city) } : null
      )
      Object.assign(
        addressManagerDetails,
        userAddress.state ? { st: sha256(userAddress.state) } : null
      )
      Object.assign(
        addressManagerDetails,
        userAddress.zip ? { zp: sha256(userAddress.zip) } : null
      )
      Object.assign(
        addressManagerDetails,
        userAddress.country
          ? { country: sha256(userAddress.country.toLowerCase()) }
          : null
      )

      if (userAddress.fullName) {
        const fullName = userAddress.fullName.split(' ')
        Object.assign(
          addressManagerDetails,
          fullName.length > 1
            ? {
                fn: sha256(fullName[0]),
                ln: sha256(fullName[fullName.length - 1]),
              }
            : null
        )
      }
    }
    return addressManagerDetails
  }

  getRelevantCookies() {
    const relevantCookies = {}
    const cookies = parseCookie() || null
    if (cookies) {
      Object.assign(
        relevantCookies,
        cookies._fbc ? { fbc: cookies._fbc } : null
      )
      Object.assign(
        relevantCookies,
        cookies._fbp ? { fbp: cookies._fbp } : null
      )
    }
    return relevantCookies
  }

  getAgentAndIp() {
    const agentAndIp = {}
    // from facebook:
    // "If you send client_ip_address or client_user_agent, you must send both keys."
    const userIp = getIPAddress()
    const userAgent = this.getUserAgent()
    Object.assign(
      agentAndIp,
      userIp && userAgent
        ? {
            client_ip_address: userIp,
            client_user_agent: userAgent,
          }
        : null
    )
    return agentAndIp
  }

  getUserData(userAddress) {
    // Facebook demand to send all some user-data hashed with SHA256 and UTF-8 encoding.
    // read more here: https://developers.facebook.com/docs/marketing-api/server-side-api/parameters/user-data
    const userData = {}
    const email = getUserEmail()
    Object.assign(userData, email ? { em: sha256(email) } : null)

    Object.assign(userData, this.getAddressManagerDetails(userAddress))
    Object.assign(userData, this.getRelevantCookies())
    Object.assign(userData, this.getAgentAndIp())

    // from Facebook: Send at least one of the following user_data parameter keys for each event sent from your server
    const atLeastOneRequired = [
      'em',
      'ph',
      'ge',
      'db',
      'ln',
      'fn',
      'ct',
      'st',
      'zp',
      'client_ip_address',
      'client_user_agent',
    ]
    const isContainsRequired = (field) => atLeastOneRequired.includes(field)

    // if not contain any of required, return false
    return Object.keys(userData).some(isContainsRequired) ? userData : false
  }
}
const facebookAnalyticsUtils = new FacebookUtils()

class ServerSideAnalytics {
  _sendPostRequest(endpoint, details) {
    return mixtilesAxios.post(endpoint, details)
  }

  getFacebookEvent(eventName, customData = {}, eventId, userData, eventTime) {
    return [
      {
        event_name: eventName,
        event_time: eventTime,
        user_data: userData,
        custom_data: customData,
        ...(eventId ? { event_id: eventId } : null),
      },
    ]
  }

  getKlaviyoEvent(email, customData = {}, eventId) {
    return {
      eventProperties: customData,
      email,
      ...(eventId ? { eventId } : null),
    }
  }

  getTiktokEvent(tiktokEventName, customData, eventId, phoneNumber, email) {
    const { ttclid } = parseCookie() || {}
    return {
      tiktokEventName,
      eventId,
      ttclid,
      customData,
      phoneNumber,
      email,
    }
  }

  getServerSideEvent(eventName, customData = {}, eventId, platforms = []) {
    try {
      const eventTime = getNowUnixTimestamp()
      const userData = facebookAnalyticsUtils.getUserData(
        storageManager.get(ADDRESSES_KEY)
      )
      const productType = productTypeState.getProductType()
      if (userData) {
        const params = {
          eventName,
          eventTime,
          productType, // We send the product type (Classic, WallDecor or Art) here. The server maps the product type into the correct FacebookPixelId
        }

        if (platforms.includes(ServerSideAnalyticsPlatform.Facebook)) {
          params.facebook = this.getFacebookEvent(
            eventName,
            customData,
            eventId,
            userData,
            eventTime
          )
        }

        const email = getUserEmail()
        if (email && platforms.includes(ServerSideAnalyticsPlatform.Klaviyo)) {
          params.klaviyo = this.getKlaviyoEvent(email, customData, eventId)
        }

        const phoneNumber = getUserPhoneNumber()
        if (
          eventName in tiktokEvents &&
          platforms.includes(ServerSideAnalyticsPlatform.Tiktok)
        ) {
          params.tiktok = this.getTiktokEvent(
            tiktokEvents[eventName],
            customData,
            eventId,
            phoneNumber,
            email
          )
        }
        return params
      }
    } catch (e) {
      logger.error(
        'Facebook server side analytics failed (event creation failed) :',
        e
      )
    }
    return undefined
  }

  async track(eventName, customData = {}, eventId, platforms) {
    if ((platforms || []).length === 0) return

    try {
      const serverSideEvent = this.getServerSideEvent(
        eventName,
        customData,
        eventId,
        platforms
      )
      if (serverSideEvent) {
        await this._sendPostRequest(ANALYTICS_URL, serverSideEvent)
      }
    } catch (e) {
      logger.error('Facebook server side analytics failed:', e)
    }
  }
}

export const serverSideAnalytics = new ServerSideAnalytics()
