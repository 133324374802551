export enum PRODUCT_TYPES {
  CLASSIC = 'classic',
  ART = 'art',
  JUMBLE = 'jumble',
  GIFT_CARD = 'giftCard',
  GALLERY_WALL = 'galleryWall',
  PHOTO_BOOK = 'photoBook',
  BUSINESS = 'business',
}

export const VOUCHER_PRODUCT_TYPES = [
  PRODUCT_TYPES.GIFT_CARD,
  PRODUCT_TYPES.BUSINESS,
]

class ProductTypeState {
  private type: PRODUCT_TYPES

  constructor() {
    this.type = PRODUCT_TYPES.CLASSIC
  }

  setProductType(type: PRODUCT_TYPES) {
    if (Object.values(PRODUCT_TYPES).includes(type)) {
      this.type = type
    } else {
      throw new Error(`Received unknown product type: ${type}`)
    }
  }

  getProductType(): PRODUCT_TYPES {
    return this.type
  }
}

export const productTypeState = new ProductTypeState()
