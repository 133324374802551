import storageManager from '../StorageManager'

const USER_PHONE_NUMBER_KEY = 'userPhoneNumber'

export function setUserPhoneNumber(phoneNumber) {
  storageManager.set(USER_PHONE_NUMBER_KEY, phoneNumber)
}

export function getUserPhoneNumber() {
  return storageManager.get(USER_PHONE_NUMBER_KEY)
}
