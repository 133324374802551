/**
 * By default, we send all tracked events to Klaviyo. The Lifecycle team doesn't want us to send events
 * which are not used as part of the lifecycle flows because we have a limit for the amount of metrics allowed.
 * Thus, we don't send events that appear on the following blacklist. They said
 * it will be easier and with less risk to decide based on a blacklist than based on a whitelist (because
 * it will be too hard to generate a list of all events that are being used for all the flows).
 *
 * Therefore, it's their responsibility of maintaining this list as we don't know which events they
 * use and which they aren't. They might want us to update this list when we reach Klaviyo's
 * limit (for exmaple because we started sending new events that don't appear on this list).
 */
const klaviyoBlacklistEvents = [
  'Used events',
  'Tile Preview Tapped',
  'Style Tapped',
  'Tile Cropped',
  'Tile Adjusted',
  'Tile Removed',
  'Check If Should Load Fullstory',
  'Page Refreshed',
  'Optimizely Datafile Loaded',
  'First Interaction',
  'Filestack Photo Selected',
  'AppsFlyer Tracking Succeeded',
  'Menu Opened',
  'Photo Prepared',
  'Attribution Survey Answered',
  'Session Start',
  'Quiz Finished Succesfully',
  'Style Clicked',
  'Mixtiles Homepage - Scrolled to Bottom',
  'Launched',
  'Bundle Selected',
  'Fetch Remote User Info Succeeded',
  'Bundle Pricing Button Clicked',
  'Photo Downloaded',
  'Lapsed Step 1',
  'Low Resolution Warning Displayed',
  'Designer Page View',
  'Initial Tile Crop Calculated',
  'Filestack Picker Opened',
  'Address Form Done Button Tapped',
  'Check Deleted S3 Photos',
  'Photo Picker Tab Selected',
  'Menu Closed',
  'Generic Banner Shown',
  'Tile Previews First Scroll',
  'Home Screen First Scroll',
  'Tile Size Drawer: Size Viewed',
  'Removed Old Photo',
  'Our Picks Scanning Completed',
  'Product Shot Swiped',
  'Talk To Us Tapped',
  'Video Played',
  'Merged Profile',
  'Album Changed',
  'Apple Pay Set By Default',
  'Redirected From Classic To Art',
  'Styles Drawer Tapped',
  'Application Did Receive Memory Warning',
  'Attribution Survey Shown',
  'Volume Discount Got It Tapped',
  'Sizes Drawer Tapped',
  'Use PayPal Tapped',
  'Consent Dialog Closed',
  'User Info Migrated',
  'See All Tapped',
  'WallDecoration Tap Frame',
  'Photos Rendered',
  'Travel Segment Added',
  'Webhook Send Successful',
  'Notification Permission Declined',
  'Waiting For Cart Tasks',
  'Cart Tasks Completed',
  'Photos Permission Granted',
  'Notification Permission Granted',
  'Tile Size Drawer: Size Tapped',
  'Load Albums Finished',
  'Sizes Drawer: Size Tapped',
  'Wall Zoomed',
  'User Created an Account',
  'Page Fully Scrolled',
  'Cart: Edit Product Tapped',
  'Complete Order After Payment',
  'Post Purchase Discount Button Pressed',
  'Replace Photo: Done Tapped',
  'Received Payment Method',
  'Splash Screen Retry Button Shown',
  'Full Story Session Started',
  'Page Loaded',
  'Start Analyzing Segments',
  'App Updated',
  'Album Tapped',
  'User Started Flow With Token',
  'Retrieved Profile Data',
  'Photo Zoom Pressed',
  'Review Dialog Attempted',
  'Photos Permission Popup Shown',
  'Promo Code Replaced',
  'Replace Photo: Image Tapped',
  'Payment Aborted',
  'Notification Received',
  'Dismiss GDPR cookie notice',
  'Wall Panned',
  'WallDecoration Style Changed',
  'Credit Card Added',
  'Add/Edit Gift Note Tapped',
  'Experiments Forced',
  'Customize Centerpiece Tapped',
  'Tile Upload Failed',
  'Photos Permission Secondary Popup Shown',
  'Analyzed Albums',
  'Apple Pay Tapped',
  'WallDecoration Sets Fetched',
  'Notification Opened',
  'Photos Permission Secondary Popup Interaction',
  'AppsFlyer Tracking Failed',
  'WallDecoration Replace Photo Tapped',
  'Empty Tile Tapped',
  'Selected Top Moments',
  'Select a Discount Button Tapped',
  'Order Management: Modifying Existing Order Dialog Confirmed',
  'WallDecoration Frame Changed',
  "Mouse Hover enabled, but device doesn't have hover",
  'Payment Method Changed',
  'Swap Photo Position Tapped',
  'WallDecoration Photo Replaced',
  'Segments Scrolled to Bottom',
  'Wall Decoration Set Preview Clicked',
  'Swap Photo Position: Photo Selected',
  'Address Validation Failed',
  'Order Management: Talk to Us Tapped',
  'Set Dimensions Tappped',
  'Card Declined',
  'User Login Failed',
  'Use ApplePay Tapped',
  'Quiz Intro Lets Go Tapped',
  'Travel Segments - Home Location Found',
  'User Profile Migration Succeeded',
  'Fulfilled Partial Order',
  'WallDecoration Tap Empty Frame',
  'User Profile Migration Failed',
  'Use Apple Pay Tapped',
  'Help Tapped',
  'Gift Card Order Confirmed',
  'Styling Strip Opened',
  'Gift Card Order Completed',
  'Gift Card Credit Card Added',
  'Cart: Product Removed',
  'Order Management: Replace / Adjust Photos Dismissed',
  'Browse Designs Tapped',
  'WallDecorTileCropper Loading Error',
  'WallDecoration Remove Photo Tapped',
  'WallDecoration Set Viewed',
  'Restored payment',
  'Photos Permission Denied',
  'Fetch Remote User Info Failed',
  'Product Page Add Photos Tapped',
  'Received Automated Response SMS',
  'Loaded First Segment Photo',
  'Styling Strip Closed',
  'WallDecoration Set Changed',
  'Order Management: Changed frame style Tapped',
  'WallDecoration Set Thumbnail Seen',
  'Image Access Failed',
  'Order Management: Change Order Address Tapped',
  'Order Management: Modifying Existing Order Dialog Dismissed',
  'Track Shipment Tapped',
  'WallDecoration User Pinched Screen',
  'Address Validation Skipped',
  'App Error Occurred',
  'Stopped Loading Travel Segments',
  'Restored photos',
  'Add Credit Card Failed',
  'Taxation Loading Seen',
  'Address Validation: Failed',
  'Zoom Button Clicked',
  'Order Management: Replaced Photo',
  'Redirected to Mixtiles 2.0',
  'Rate Us Shown',
  "Facebook didn't respond in time",
  'Restored address',
  'Rate Us Answered',
  'Static Photo Tapped',
  'Wall Decoration Sets Fetched',
  'Order Management: Change tiles content Completed',
  'Rating Screen Opened',
  'Image Size Reduced',
  'Try Again Tapped',
  'Exceeding Photos Modal View',
  'Product Page Photos Added',
  'Order Management: Changed frame style Dismissed',
  'Order Management: Change Order Address Dismissed',
  '[Performance] Start Loading Art Product',
  'Exceeding Photos Modal: Done Tapped',
  'Image Upload Completed with time',
  'Address Validation Backend Error',
  'Charge Failed',
  'Rating #1 next tapped',
  'User Crop Failed',
  '[Performance] Discovery Sections Loaded',
  'Change Order Address Tapped',
  'Rating #2 next tapped',
  'Rating Submitted',
  'Gift Card Use Saved Credit Card',
  'Order Management: Change Order Address Confirmed',
  'Order Management: Changed frame style Confirmed',
  'Styling Strip Category Changed',
  'WallDecoration Background Images Loaded',
  'Order Management: Change Order Address Completed',
  'Image Size Mismatch',
  'Updated Email Preferences',
  'Passed choose gift step',
  'Order Management: Changed frame style Completed',
  'Building Sets Done',
  'Gift Card Help Tapped',
  'Use Your Credit Card Tapped',
  'quiz',
  'Remove Promo Code Tapped',
  'WallDecoration Adjust Crop Tapped',
  'Scan Card Tapped',
  'Change Order Address Confirmed',
  'Tile Thumbnail Loaded',
  'Photo Pre-Signed URL Retrieved',
  'Uploaded Photo Dimensions Saved',
  'Photo Tapped',
  'Cart Changed',
  'Sizes Drawer: Size Viewed',
  'Promo Code Loaded Automatically',
  'Tile Preview Tapped',
  'Tile Smart Cropped',
  'Pick Photos Tapped',
  'Add Photos Tapped',
  'Create A Set Tapped',
  'Style Tapped',
  'Adjust Photo Tapped',
  'Tile Cropped',
  'Tile Adjusted',
  'Tile Removed',
  'AppsFlyer Tracking Succeeded',
  'Lapsed Step 1',
  'Check If Should Load Fullstory',
  'Started Checkout',
  'Add Tile Tapped',
  'Promo Code Popup Shown',
  'Page Refreshed',
  'Optimizely Datafile Loaded',
  'First Interaction',
  'Menu Item Tapped',
  'Homepage Rendered',
  'Menu Opened',
  'Photo Prepared',
  'Initial Tile Crop Calculated',
  'Fetch Remote User Info Succeeded',
  'Filestack Photo Selected',
  'Sizes Drawer: Done Tapped',
  'Face Detection Init Started',
  'Quiz Finished Succesfully',
  'Face Detection Init Finished',
  'Tile Size Drawer Tapped',
  'Tile Size Drawer: Done Tapped',
  'Frame Drawer: Frame Tapped',
  'Designer Page View',
  'Mat Drawer: Mat Tapped',
  'Photo Upload Failed',
  'Photo Picker Open Clicked',
  'Payment Method Selection Dialog Tapped',
  'Mixtiles Homepage - Scrolled to Bottom',
  'Bundle Pricing Button Clicked',
  'Entrypoint Rendered',
  'Product Card Viewed',
  'Video/Story Paused',
  'OS Picker Opened',
  'Tile Filter Drawer: Filter Tapped',
  'Screen Dragged',
  'Video/Story Played',
  '[Performance] Start App Flow',
  'Filter Drawer: Filter Tapped',
  'Video/Story Completed',
  'Designer Page View',
]

export default klaviyoBlacklistEvents
