import { inGdprCountry } from '../../../config/gdpr'
import klaviyoBlacklistEvents from './klaviyoBlacklistEvents'
import { getUrlParam } from '../../../utils/UrlUtils'
import { getUserAttributions } from '../../../utils/ApiUtils'
import omit from 'lodash/omit'
import { getUserEmail } from '../../userEmail'
import { isServer } from 'utils/runtimeUtils'

class Klaviyo {
  init() {
    this.email = getUserEmail()
  }

  identifyUser(userId) {
    this.userId = userId
    this._identify()
  }

  track(eventName, eventProperties = {}) {
    if (!klaviyoBlacklistEvents.includes(eventName)) {
      this._push(
        'track',
        eventName,
        this._addIdentityProperties(eventProperties)
      )
    }
  }

  setEmail(email) {
    this.email = email
  }

  async setInitialUserId() {
    const initialUserParams = (await getUserAttributions()).initialParams || {}
    const initialUserIdUrlParam = getUrlParam('initial_user_id')
    this.initialUserId =
      initialUserParams.initial_user_id || initialUserIdUrlParam || this.userId
    this._identify({
      InitialUserID: this.initialUserId,
    })
  }

  setName(name) {
    let [firstName, ...lastName] = name.split(' ')
    lastName = lastName.join(' ') || undefined
    this._identify({
      $first_name: firstName,
      $last_name: lastName,
    })
  }

  setLanguage(language) {
    this._identify({ language })
  }

  setPhoneNumber(number) {
    const timestamp = new Date().getTime() * 0.001
    this._identify({
      phone_number: number,
      $consent_timestamp: Math.floor(timestamp),
    })
  }

  setProfileToken(profileToken) {
    this._identify({ $profile_token: profileToken })
  }

  setLastOrderTimestamp(lastOrderTimestamp) {
    this._identify({
      last_order_timestamp: lastOrderTimestamp,
    })
  }

  setSmsScreenLastSeen(lastSeenTimestamp) {
    this._identify({
      sms_screen_last_seen: lastSeenTimestamp,
    })
  }

  setUserProperties(userProperties) {
    this._push('identify', omit(userProperties, 'Email')) // Klaviyo email is set separately as $email, remove it to prevent duplication
  }

  _identify(properties = {}) {
    this._push('identify', this._addIdentityProperties(properties))
  }

  _push(...params) {
    if (isServer()) return
    if (!window._learnq) {
      window._learnq = []
    }

    window._learnq.push([...params])
  }

  _addIdentityProperties(properties) {
    // (!) IMPORTANT: Copy the properties so it won't affect other analytics (and send the email there too)
    properties = { ...properties }

    if (this.userId) {
      properties.UserId = this.userId
    }
    if (this.email) {
      properties.$email = this.email
    }
    if (inGdprCountry()) {
      // We want any user that has any event from a GDPR country to be marked as a
      // GDPR user in Klaviyo, for marketing purposes.
      properties.isGDPR = true
    }

    return properties
  }
}

export const klaviyo = new Klaviyo()
