import cc from '../locale/custom/countries.json'

type Locale = {
  countryCode: string
  country: string
}

export function getIPAddress() {
  return window.KEYS.ipAddress
}

export function getCountrySource() {
  return window.KEYS.countrySource
}

export function getCountry() {
  return window.KEYS.ipCountry
}

export function getState() {
  return window.KEYS.ipState
}

export function getLocale(): Locale {
  const countryCode = getCountry()
  return {
    countryCode,
    country: cc.en[countryCode as keyof typeof cc.en],
  }
}
