import * as Sentry from '@sentry/remix'

import { captureConsoleIntegration } from '@sentry/integrations'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useNavigate,
} from 'react-router-dom'
import { useEffect } from 'react'

class SentryService {
  initSentry(keys) {
    if (window.KEYS.runtimeEnv !== 'local') {
      try {
        const defaultIntegrations = [
          captureConsoleIntegration({ levels: ['error'] }),
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigate,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ]

        Sentry.init({
          dsn: 'https://04d99f9858cf47bdb586902e3b11a797@o223238.ingest.us.sentry.io/1372017',
          environment: keys.runtimeEnv,
          release: keys.buildCommit,
          attachStacktrace: true,
          integrations: defaultIntegrations,
          tracesSampleRate: Number(keys.tracesSampleRate) || 0.1,
          initialScope: {
            extra: { 'Capture Level': 'Remix Client' },
            contexts: { app: {} },
          },
        })
      } catch (error) {
        console.error(
          'Error initiating Sentry.. will anyone ever see this??',
          error
        )
      }
    }
  }

  identifyUser(userId) {
    Sentry.setUser({ id: userId })
  }

  reportErrorManually(exception) {
    Sentry.captureException(exception)
  }
}

export const sentryService = new SentryService()
