import { LogglyTracker } from 'loggly-jslogger'

class LogglyWriter {
  constructor(logglyApiKey, logglySource) {
    this.tracker = new LogglyTracker()
    this.tracker.push({
      logglyKey: logglyApiKey,
      tag: logglySource,
      useUtfEncoding: true,
    })
  }

  write(level, message, metadata) {
    this.tracker.track({
      level,
      message,
      ...metadata,
    })
  }
}

export function createLogglyWriter(logglyApiKey, logglySource) {
  return new LogglyWriter(logglyApiKey, logglySource)
}
