import StorageManager from './StorageManager'

const USER_EMAIL_KEY = 'userEmail'
export const INITIAL_USER_KEY = 'initialUserId'

export const getUserEmail = () => {
  return StorageManager.get(USER_EMAIL_KEY)
}

export const setUserEmail = (email) => {
  StorageManager.set(USER_EMAIL_KEY, email)
}

export const clearUserEmail = () => {
  StorageManager.remove(USER_EMAIL_KEY)
}
