import { browserName, getUA } from 'react-device-detect'

export const INSTAGRAM_BROWSER = 'Instagram'
export const FACEBOOK_BROWSER = 'Facebook'

export function getUserBrowser() {
  const userAgent = getUA
  if (userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1) {
    return FACEBOOK_BROWSER
  } else if (userAgent.indexOf('Instagram') > -1) {
    return INSTAGRAM_BROWSER
  }

  return browserName
}

export function isSmallScreen() {
  return window.innerWidth < 650
}

export function quicklookSupportExists() {
  const a = document.createElement('a')
  return a && a.relList && a.relList.supports('ar')
}
