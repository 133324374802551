import { STATES_DATA } from '../config/states-data'

export const ADDRESSES_KEY = 'currentAddress'

export function canVerifyCountryStates(countryCode) {
  return STATES_DATA[countryCode.toUpperCase()] != null
}

export function verifyState(countryCode, state) {
  return normalizeState(countryCode, state) != null
}

/*
  Returns 2 letters code of the given state, or null if invalid
*/
export function normalizeState(countryCode, state) {
  if (!state) {
    return null
  }
  const stateClean = state.toUpperCase().replace(/\s+/g, '') // Uppercase and remove spaces
  const countryStates = STATES_DATA[countryCode.toUpperCase()]
  return countryStates[stateClean] && countryStates[stateClean].code
}
