export const STATES_DATA = {
  // USA States
  US: {
    AA: {
      code: 'AA',
      fullname: 'AA',
    },
    AE: {
      code: 'AE',
      fullname: 'AE',
    },
    AK: {
      code: 'AK',
      fullname: 'AK',
    },
    AL: {
      code: 'AL',
      fullname: 'AL',
    },
    ALA: {
      code: 'AL',
      fullname: 'Alabama',
    },
    ALABAMA: {
      code: 'AL',
      fullname: 'Alabama',
    },
    ALASKA: {
      code: 'AK',
      fullname: 'Alaska',
    },
    AMERICANSAMOA: {
      code: 'AS',
      fullname: 'American Samoa',
    },
    AP: {
      code: 'AP',
      fullname: 'AP',
    },
    APOAA: {
      code: 'APO AA',
      fullname: 'APO AA',
    },
    APOAE: {
      code: 'APO AE',
      fullname: 'APO AE',
    },
    APOAP: {
      code: 'APO AP',
      fullname: 'APO AP',
    },
    AR: {
      code: 'AR',
      fullname: 'AR',
    },
    ARIZONA: {
      code: 'AZ',
      fullname: 'Arizona',
    },
    ARKANSAS: {
      code: 'AR',
      fullname: 'Arkansas',
    },
    AS: {
      code: 'AS',
      fullname: 'AS',
    },
    AZ: {
      code: 'AZ',
      fullname: 'AZ',
    },
    CA: {
      code: 'CA',
      fullname: 'CA',
    },
    CALIF: {
      code: 'CA',
      fullname: 'California',
    },
    CALIFORNIA: {
      code: 'CA',
      fullname: 'California',
    },
    CO: {
      code: 'CO',
      fullname: 'CO',
    },
    COLORADO: {
      code: 'CO',
      fullname: 'Colorado',
    },
    CONNECTICUT: {
      code: 'CT',
      fullname: 'Connecticut',
    },
    CT: {
      code: 'CT',
      fullname: 'CT',
    },
    DC: {
      code: 'DC',
      fullname: 'DC',
    },
    DE: {
      code: 'DE',
      fullname: 'DE',
    },
    DELAWARE: {
      code: 'DE',
      fullname: 'Delaware',
    },
    DISTRICTOFCOLUMBIA: {
      code: 'DC',
      fullname: 'District of Columbia',
    },
    DPOAA: {
      code: 'DPO AA',
      fullname: 'DPO AA',
    },
    DPOAE: {
      code: 'DPO AE',
      fullname: 'DPO AE',
    },
    DPOAP: {
      code: 'DPO AP',
      fullname: 'DPO AP',
    },
    FL: {
      code: 'FL',
      fullname: 'FL',
    },
    FLORIDA: {
      code: 'FL',
      fullname: 'Florida',
    },
    FPOAA: {
      code: 'FPO AA',
      fullname: 'FPO AA',
    },
    FPOAE: {
      code: 'FPO AE',
      fullname: 'FPO AE',
    },
    FPOAP: {
      code: 'FPO AP',
      fullname: 'FPO AP',
    },
    GA: {
      code: 'GA',
      fullname: 'GA',
    },
    GEORGIA: {
      code: 'GA',
      fullname: 'Georgia',
    },
    GU: {
      code: 'GU',
      fullname: 'GU',
    },
    GUAM: {
      code: 'GU',
      fullname: 'Guam',
    },
    HAWAII: {
      code: 'HI',
      fullname: 'Hawaii',
    },
    HI: {
      code: 'HI',
      fullname: 'HI',
    },
    IA: {
      code: 'IA',
      fullname: 'IA',
    },
    ID: {
      code: 'ID',
      fullname: 'ID',
    },
    IDAHO: {
      code: 'ID',
      fullname: 'Idaho',
    },
    IL: {
      code: 'IL',
      fullname: 'IL',
    },
    ILINOIS: {
      code: 'IL',
      fullname: 'Illinois',
    },
    ILLINOIS: {
      code: 'IL',
      fullname: 'Illinois',
    },
    IN: {
      code: 'IN',
      fullname: 'IN',
    },
    INDIANA: {
      code: 'IN',
      fullname: 'Indiana',
    },
    IOWA: {
      code: 'IA',
      fullname: 'Iowa',
    },
    KANSAS: {
      code: 'KS',
      fullname: 'Kansas',
    },
    KENTUCKY: {
      code: 'KY',
      fullname: 'Kentucky',
    },
    KS: {
      code: 'KS',
      fullname: 'KS',
    },
    KY: {
      code: 'KY',
      fullname: 'KY',
    },
    LA: {
      code: 'LA',
      fullname: 'LA',
    },
    LOUISIANA: {
      code: 'LA',
      fullname: 'Louisiana',
    },
    MA: {
      code: 'MA',
      fullname: 'MA',
    },
    MAINE: {
      code: 'ME',
      fullname: 'Maine',
    },
    MARYLAND: {
      code: 'MD',
      fullname: 'Maryland',
    },
    MARYLSND: {
      code: 'MD',
      fullname: 'Maryland',
    },
    MASSACHUSETTS: {
      code: 'MA',
      fullname: 'Massachusetts',
    },
    MASSACHUSSETS: {
      code: 'MA',
      fullname: 'Massachusetts',
    },
    MD: {
      code: 'MD',
      fullname: 'MD',
    },
    ME: {
      code: 'ME',
      fullname: 'ME',
    },
    MI: {
      code: 'MI',
      fullname: 'MI',
    },
    MICHIGAN: {
      code: 'MI',
      fullname: 'Michigan',
    },
    MINNESOTA: {
      code: 'MN',
      fullname: 'Minnesota',
    },
    MISSISSIPPI: {
      code: 'MS',
      fullname: 'Mississippi',
    },
    MISSOURI: {
      code: 'MO',
      fullname: 'Missouri',
    },
    MN: {
      code: 'MN',
      fullname: 'MN',
    },
    MO: {
      code: 'MO',
      fullname: 'MO',
    },
    MONTANA: {
      code: 'MT',
      fullname: 'Montana',
    },
    MP: {
      code: 'MP',
      fullname: 'MP',
    },
    MS: {
      code: 'MS',
      fullname: 'MS',
    },
    MT: {
      code: 'MT',
      fullname: 'MT',
    },
    NC: {
      code: 'NC',
      fullname: 'NC',
    },
    ND: {
      code: 'ND',
      fullname: 'ND',
    },
    NE: {
      code: 'NE',
      fullname: 'NE',
    },
    NEBRASKA: {
      code: 'NE',
      fullname: 'Nebraska',
    },
    NEVADA: {
      code: 'NV',
      fullname: 'Nevada',
    },
    NEWHAMPSHIRE: {
      code: 'NH',
      fullname: 'New Hampshire',
    },
    NEWJERSEY: {
      code: 'NJ',
      fullname: 'New Jersey',
    },
    NEWMEXICO: {
      code: 'NM',
      fullname: 'New Mexico',
    },
    NEWYORK: {
      code: 'NY',
      fullname: 'New York',
    },
    NH: {
      code: 'NH',
      fullname: 'NH',
    },
    NJ: {
      code: 'NJ',
      fullname: 'NJ',
    },
    NM: {
      code: 'NM',
      fullname: 'NM',
    },
    NORTHCAROLINA: {
      code: 'NC',
      fullname: 'North Carolina',
    },
    NORTHDAKOTA: {
      code: 'ND',
      fullname: 'North Dakota',
    },
    NORTHERNMARIANAISLANDS: {
      code: 'MP',
      fullname: 'Northern Mariana Islands',
    },
    NV: {
      code: 'NV',
      fullname: 'NV',
    },
    NY: {
      code: 'NY',
      fullname: 'NY',
    },
    OH: {
      code: 'OH',
      fullname: 'OH',
    },
    OHIO: {
      code: 'OH',
      fullname: 'Ohio',
    },
    OK: {
      code: 'OK',
      fullname: 'OK',
    },
    OKLAHOMA: {
      code: 'OK',
      fullname: 'Oklahoma',
    },
    OR: {
      code: 'OR',
      fullname: 'OR',
    },
    OREGON: {
      code: 'OR',
      fullname: 'Oregon',
    },
    PA: {
      code: 'PA',
      fullname: 'PA',
    },
    PENNSYLVANIA: {
      code: 'PA',
      fullname: 'Pennsylvania',
    },
    PR: {
      code: 'PR',
      fullname: 'PR',
    },
    PUERTORICO: {
      code: 'PR',
      fullname: 'Puerto Rico',
    },
    RHODEISLAND: {
      code: 'RI',
      fullname: 'Rhode Island',
    },
    RI: {
      code: 'RI',
      fullname: 'RI',
    },
    SC: {
      code: 'SC',
      fullname: 'SC',
    },
    SD: {
      code: 'SD',
      fullname: 'SD',
    },
    SOUTHCAROLINA: {
      code: 'SC',
      fullname: 'South Carolina',
    },
    SOUTHDAKOTA: {
      code: 'SD',
      fullname: 'South Dakota',
    },
    TENNESSEE: {
      code: 'TN',
      fullname: 'Tennessee',
    },
    TEXAD: {
      code: 'TX',
      fullname: 'Texas',
    },
    TEXAS: {
      code: 'TX',
      fullname: 'Texas',
    },
    TN: {
      code: 'TN',
      fullname: 'TN',
    },
    TX: {
      code: 'TX',
      fullname: 'TX',
    },
    USVIRGINISLANDS: {
      code: 'VI',
      fullname: 'U.S. Virgin Islands',
    },
    UT: {
      code: 'UT',
      fullname: 'UT',
    },
    UTAH: {
      code: 'UT',
      fullname: 'Utah',
    },
    VA: {
      code: 'VA',
      fullname: 'VA',
    },
    VERMONT: {
      code: 'VT',
      fullname: 'Vermont',
    },
    VI: {
      code: 'VI',
      fullname: 'VI',
    },
    VIRGINA: {
      code: 'VA',
      fullname: 'Virginia',
    },
    VIRGINIA: {
      code: 'VA',
      fullname: 'Virginia',
    },
    VIRGINISLANDS: {
      code: 'VI',
      fullname: 'U.S. Virgin Islands',
    },
    VT: {
      code: 'VT',
      fullname: 'VT',
    },
    WA: {
      code: 'WA',
      fullname: 'WA',
    },
    WASHINGTON: {
      code: 'WA',
      fullname: 'Washington',
    },
    WASHINGTONDC: {
      code: 'WA',
      fullname: 'Washington',
    },
    WESTVIRGINIA: {
      code: 'WV',
      fullname: 'West Virginia',
    },
    WI: {
      code: 'WI',
      fullname: 'WI',
    },
    WISCONSIN: {
      code: 'WI',
      fullname: 'Wisconsin',
    },
    WV: {
      code: 'WV',
      fullname: 'WV',
    },
    WY: {
      code: 'WY',
      fullname: 'WY',
    },
    WYOMING: {
      code: 'WY',
      fullname: 'Wyoming',
    },
  },

  // Canada States
  CA: {
    ON: {
      fullname: 'ON',
      code: 'ON',
    },
    ONTARIO: {
      fullname: 'Ontario',
      code: 'ON',
    },
    ONT: {
      fullname: 'Ontario',
      code: 'ON',
    },
    QC: {
      fullname: 'QC',
      code: 'QC',
    },
    QUEBEC: {
      fullname: 'Quebec',
      code: 'QC',
    },
    QUE: {
      fullname: 'Quebec',
      code: 'QC',
    },
    NS: {
      fullname: 'NS',
      code: 'NS',
    },
    NOVASCOTIA: {
      fullname: 'Nova Scotia',
      code: 'NS',
    },
    NE: {
      fullname: 'Nova Scotia',
      code: 'NS',
    },
    NB: {
      fullname: 'NB',
      code: 'NB',
    },
    NEWBRUNSWICK: {
      fullname: 'New Brunswick',
      code: 'NB',
    },
    MB: {
      fullname: 'MB',
      code: 'MB',
    },
    MANITOBA: {
      fullname: 'Manitoba',
      code: 'MB',
    },
    MAN: {
      fullname: 'Manitoba',
      code: 'MB',
    },
    BC: {
      fullname: 'BC',
      code: 'BC',
    },
    BRITISHCOLUMBIA: {
      fullname: 'British Columbia',
      code: 'BC',
    },
    CB: {
      fullname: 'British Columbia',
      code: 'British Columbia',
    },
    PE: {
      fullname: 'PE',
      code: 'PE',
    },
    PRINCEEDWARDISLAND: {
      fullname: 'Prince Edward Island',
      code: 'PE',
    },
    PEI: {
      fullname: 'Prince Edward Island',
      code: 'PE',
    },
    IPE: {
      fullname: 'Prince Edward Island',
      code: 'PE',
    },
    SK: {
      fullname: 'SK',
      code: 'SK',
    },
    SASKATCHEWAN: {
      fullname: 'Saskatchewan',
      code: 'SK',
    },
    SASK: {
      fullname: 'Saskatchewan',
      code: 'SK',
    },
    AB: {
      fullname: 'AB',
      code: 'AB',
    },
    ALBERTA: {
      fullname: 'Alberta',
      code: 'AB',
    },
    ALTA: {
      fullname: 'Alberta',
      code: 'AB',
    },
    ALB: {
      fullname: 'Alberta',
      code: 'AB',
    },
    NL: {
      fullname: 'NL',
      code: 'NL',
    },
    NEWFOUNDLANDANDLABRADOR: {
      fullname: 'Newfoundland and Labrador',
      code: 'NL',
    },
    TNL: {
      fullname: 'Newfoundland and Labrador',
      code: 'NL',
    },
    NT: {
      fullname: 'NT',
      code: 'NT',
    },
    NORTHWESTTERRITORIES: {
      fullname: 'Northwest Territories',
      code: 'NT',
    },
    NWT: {
      fullname: 'Northwest Territories',
      code: 'NT',
    },
    NU: {
      fullname: 'NU',
      code: 'NU',
    },
    NUNAVUT: {
      fullname: 'Nunavut',
      code: 'NU',
    },
    NVT: {
      fullname: 'Nunavut',
      code: 'NU',
    },
    YT: {
      fullname: 'YT',
      code: 'YT',
    },
    YUKON: {
      fullname: 'Yukon',
      code: 'YT',
    },
    YUK: {
      fullname: 'Yukon',
      code: 'YT',
    },
    YN: {
      fullname: 'Yukon',
      code: 'YT',
    },
  },
}
