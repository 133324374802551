import { type ExperimentClient } from '@amplitude/experiment-js-client'
import { createContext, useContext } from 'react'

export const ExperimentContext = createContext<ExperimentClient | null>(null)

export function useExperimentClient() {
  const client = useContext(ExperimentContext)
  if (!client) {
    throw new Error('Empty ExperimentContext')
  }

  return client
}
